@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://fonts.googleapis.com/css2?family=Karla);
@import url(https://fonts.googleapis.com/css2?family=Karla);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3%;
}

.container {
  width: 100%;
  max-width: 900px;
  margin: 0% auto;
}

.profilepic {
  object-fit: contain;
  max-width: 700px;
  max-height: 700px;
  width: 100%;
  height: 100%;
}

.profilepicwrapper {
  width: 30%;
}

@media only screen and (max-width: 700px) {  
  .container {
    width: 100%;
    max-width: 500px;
    margin: 0% auto;
  }
  .flex-container {
    display: inline;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 3%;
  } 

  .profilepic {
    object-fit: contain;
    max-width: 500px;
    max-height: 500px;
    width: 50%;
    height: 50%;
    margin-left: 25%;
  }

  .profilepicwrapper {
    width: 100%;
    margin: 0% auto;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1300px) {
  p {
    font-size: large !important; 
  }

  a {
    font-size: large !important;
  }

  div {
    font-size: large !important;
  }
}  


.heading-style {
    font-family: "Karla";
    font-weight: 800;
    color:black;
    margin-left:10%;
    margin-top: 8%;
    margin-bottom: 3%;
    font-size: xx-large;
}

.subheading-style{
    font-family: "Karla";
    font-weight: 800;
    color:black;
    margin-left:10%;
    margin-top: 6%;
    font-size: xx-large;
}

.bio-style {
    font-family: "Karla";
    font-weight: 600;
    font-size: medium;
    margin-left:5%;
    margin-right: 10%;  
    color: black;
}

@media only screen and (max-width: 700px) { 
    .typewritertext {
        display: none;
    }

    .introduction{
        display: none;
    }

    .heading-style {
        font-family: "Karla";
        font-weight: 800;
        color:black;
        margin-left:10%;
        margin-top: 8%;
        margin-bottom: 3%;
        font-size: xx-large;
    }
    
    .subheading-style{
        font-family: "Karla";
        font-weight: 800;
        color:black;
        margin-left:10%;
        margin-top: 6%;
        font-size: xx-large;
    }
    
    .bio-style {
        font-family: "Karla";
        font-weight: 600;
        font-size: medium;
        margin-left:10%;
        margin-right: 10%;  
        color: black;
    }
}
a.project {
    font-family: "Karla";
    font-size: medium;
    text-decoration: none;
    font-weight: 700;
    color: #7599AA;
    text-decoration: none;
}

a.project:hover {
    color: #E4A79C;
}

p.project {
    font-family: "Karla";
    font-size: medium;
    font-weight: 700;
    margin-left: 10%;
    color: black;
    margin-right: 10%;  

}

@media only screen and (max-width: 700px) { 
    p.project {
        margin-bottom: 7%;
    }

}
div.bio {
    margin-top:"15%";
}

a.bio {
    font-family: "Karla";
    font-weight: 700;
    font-size: medium;
    color: #7599AA;
    text-decoration: none;
}

a.bio:hover {
    color: #E4A79C;
}

img.social {
    margin-right: 4%;
}

@media only screen and (max-width: 700px) { 
    img.social {
        margin-top: 5%;
        margin-right: 8%;
        width: 10%;
        height: 10%;
    }
}
