
@import 'https://fonts.googleapis.com/css?family=Karla';

.heading-style {
    font-family: "Karla";
    font-weight: 800;
    color:black;
    margin-left:10%;
    margin-top: 8%;
    margin-bottom: 3%;
    font-size: xx-large;
}

.subheading-style{
    font-family: "Karla";
    font-weight: 800;
    color:black;
    margin-left:10%;
    margin-top: 6%;
    font-size: xx-large;
}

.bio-style {
    font-family: "Karla";
    font-weight: 600;
    font-size: medium;
    margin-left:5%;
    margin-right: 10%;  
    color: black;
}

@media only screen and (max-width: 700px) { 
    .typewritertext {
        display: none;
    }

    .introduction{
        display: none;
    }

    .heading-style {
        font-family: "Karla";
        font-weight: 800;
        color:black;
        margin-left:10%;
        margin-top: 8%;
        margin-bottom: 3%;
        font-size: xx-large;
    }
    
    .subheading-style{
        font-family: "Karla";
        font-weight: 800;
        color:black;
        margin-left:10%;
        margin-top: 6%;
        font-size: xx-large;
    }
    
    .bio-style {
        font-family: "Karla";
        font-weight: 600;
        font-size: medium;
        margin-left:10%;
        margin-right: 10%;  
        color: black;
    }
}